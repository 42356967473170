<template>
    <Layout>
        <h4>New Landscaping Request</h4>
        <br />

        <form
            class="form-horizontal vld-parent"
            @submit.prevent="requestLandscapingRequest"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="land.unit_id"
                            :options="mappedUserUnits"
                            :class="{
                                'is-invalid':
                                    submitted && $v.land.unit_id.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="submitted && $v.land.unit_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.land.unit_id.required"
                                >Unit is required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname"> Date For Visit*</label>
                        <b-form-datepicker
                            :min="min"
                            v-model="land.date"
                            :class="{
                                'is-invalid': submitted && $v.land.date.$error,
                            }"
                        ></b-form-datepicker>

                        <div
                            v-if="submitted && $v.land.date.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.land.date.required"
                                >Date is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Contractor Name*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            v-model="land.contractor_name"
                            :class="{
                                'is-invalid':
                                    submitted && $v.land.contractor_name.$error,
                            }"
                        />
                        <div
                            v-if="submitted && $v.land.contractor_name.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.land.contractor_name.required"
                                >Contractor name required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Contact Person*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            v-model="land.contact_person_name"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.land.contact_person_name.$error,
                            }"
                        />
                        <div
                            v-if="
                                submitted && $v.land.contact_person_name.$error
                            "
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.land.contact_person_name.required"
                                >Contact person name required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Office Telephone Number*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            v-model="land.contact_person_office_no"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.land.contact_person_office_no.$error,
                            }"
                        />
                        <div
                            v-if="
                                submitted &&
                                $v.land.contact_person_office_no.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="
                                    !$v.land.contact_person_office_no.required
                                "
                                >Telephone number required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Mobile Number*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            v-model="land.contact_person_mobile_no"
                            id="username"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.land.contact_person_mobile_no.$error,
                            }"
                        />
                        <div
                            v-if="
                                submitted &&
                                $v.land.contact_person_mobile_no.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="
                                    !$v.land.contact_person_mobile_no.required
                                "
                                >Mobile number required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Fax</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            v-model="land.contact_person_fax_no"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.land.contact_person_fax_no.$error,
                            }"
                            id="username"
                        />
                        <div
                            v-if="
                                submitted &&
                                $v.land.contact_person_fax_no.$error
                            "
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.land.contact_person_fax_no.numeric"
                                >Only numeric values allowed</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname">Type Of Landscaping*</label>

                    <b-form-checkbox-group
                        :options="landscapingOptions"
                        v-model="land.landscapingType"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                        @change="showOther()"
                        :class="{
                            'is-invalid':
                                submitted && $v.land.landscapingType.$error,
                        }"
                    ></b-form-checkbox-group>
                    <div
                        v-if="submitted && $v.land.landscapingType.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.land.landscapingType.required"
                            >Landscaping type required</span
                        >
                    </div>
                </div>
            </div>

            <div class="row mt-2" v-if="isOther">
                <div class="col-lg-12">
                    <label for="ownerfname"> Other</label>
                    <b-form-textarea
                        id="textarea-small"
                        v-model="land.type_others"
                        size="sm"
                    ></b-form-textarea>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-lg-12">
                    <label for="ownerfname">
                        Description of your alteration*</label
                    >
                    <div class="form-group">
                        <b-form-textarea
                            id="textarea-small"
                            v-model="land.details"
                            size="sm"
                            :class="{
                                'is-invalid':
                                    submitted && $v.land.details.$error,
                            }"
                        ></b-form-textarea>
                        <div
                            v-if="submitted && $v.land.details.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.land.details.required"
                                >Description is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6" v-if="this.role === 'Owner'">
                    <label for="ownerfname">Title Deed Copy*</label>
                    <b-form-file
                        id="titleDeed"
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.land.title_deed.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.land.title_deed.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.land.title_deed.required"
                            >Title deed is required</span
                        >
                    </div>
                </div>

                <div class="col-lg-6" v-if="this.role === 'Tenant'">
                    <label for="ownerfname">Tenancy Contract Copy*</label>
                    <b-form-file
                        id="tenancyContract"
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.land.tenancy_contract.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.land.tenancy_contract.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.land.tenancy_contract.required"
                            >Tenancy contract is required</span
                        >
                    </div>
                </div>

                <div class="col-lg-6">
                    <label for="ownerfname">Letter Of Agreement*</label>
                    <b-form-file
                        id="agreement"
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.land.letter_of_agreement.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.land.letter_of_agreement.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.land.letter_of_agreement.required"
                            >Agreement letter is required</span
                        >
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <label for="ownerfname">Scope Of Work*</label>
                    <b-form-file
                        id="scope"
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        :class="{
                            'is-invalid':
                                submitted && $v.land.scope_of_work.$error,
                        }"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.land.scope_of_work.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.land.scope_of_work.required"
                            >Scope of work is required</span
                        >
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname">Drawings*</label>
                    <b-form-file
                        id="drawing"
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid': submitted && $v.land.drawings.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.land.drawings.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.land.drawings.required"
                            >Drawing is required</span
                        >
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <label for="ownerfname">Company Request Letter*</label>
                    <b-form-file
                        id="companyRequest"
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.land.company_request_letter.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="
                            submitted && $v.land.company_request_letter.$error
                        "
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.land.company_request_letter.required"
                            >Company request letter is required</span
                        >
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname"> Company Trade License Copy*</label>
                    <b-form-file
                        id="companyTrade"
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.land.company_trade_license.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.land.company_trade_license.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.land.company_trade_license.required"
                            >Trade license is required</span
                        >
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <label for="ownerfname"
                        >Company Vehicle Registration Detail*</label
                    >
                    <b-form-file
                        placeholder="Choose File"
                        id="companyVehicle"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.land.company_vehicle_registration_detail
                                    .$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="
                            submitted &&
                            $v.land.company_vehicle_registration_detail.$error
                        "
                        class="invalid-feedback"
                    >
                        <span
                            v-if="
                                !$v.land.company_vehicle_registration_detail
                                    .required
                            "
                            >Vehicle registration letter is required</span
                        >
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname">
                        Company Driving License Copy*</label
                    >
                    <b-form-file
                        placeholder="Choose File"
                        id="companyDriving"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.land.company_driving_license.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="
                            submitted && $v.land.company_driving_license.$error
                        "
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.land.company_driving_license.required"
                            >Release letter is required</span
                        >
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <label for="ownerfname"
                        >Sub Contractor Company Letter</label
                    >
                    <b-form-file
                        placeholder="Choose File"
                        id="companyContractorLetter"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                    ></b-form-file>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname">
                        Sub Contractor Company Trade License</label
                    >
                    <b-form-file
                        placeholder="Choose File"
                        id="contractorTrade"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter($event)"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                    ></b-form-file>
                </div>
            </div>
            <h5>Terms & Conditions</h5>

            <ol>
                <li>
                    Please complete this form and submit it to the “Falconcity
                    city Hall office” or send an email to
                    <span class="email">customerservice@falconcity.com</span>
                </li>
                <li>
                    The owner/tenant is required to pay Landscaping NOC fee AED
                    500.00 (Cash non-refundable) for any work related to
                    landscaping.
                </li>
                <li>
                    if the work is major the owner’s/tenants is required to
                    deposit AED 5,000 (Cheque or cash) as a refundable security
                    deposit. (Only cash payment will be accepted from the
                    tenant).
                </li>
                <li>
                    Planting Conocarpus trees including Damas trees is strictly
                    prohibited inside your villa.
                </li>
                <li>
                    Trees must be planted 0.80 to 1.00 m and Palms 1.5m away
                    from boundary wall.
                </li>
                <li>
                    Failure to submit any of the above documents will result in
                    the cancellation of your request.
                </li>
                <li>
                    Work cannot be started without NOC & Gate pass even material
                    delivery is not allowed.
                </li>
                <li>
                    In case of security deposit cheque bounces, there is penalty
                    of AED 290.00
                </li>
                <li>AED 55/- to be paid for each gate pass.</li>
                <li>
                    Homeowners & tenants are responsible for any damage caused
                    to the common areas while carrying landscaping work in your
                    villa.
                </li>
                <li>
                    In case of any damages caused in the common areas the
                    occupant must rectify the damages within 48 hours. If the
                    damages are not rectified on time, Falconcity of Wonders
                    L.L.C. will repair the damages at the unit owner’s cost.
                </li>
                <li>
                    Contractor should abide by the access and security Policies
                    of the community at all times.
                </li>
                <li>
                    Area must be cleaned after the work is completed and the
                    waste disposed of immediately, in a proper manner.
                </li>
                <li>
                    Applicable penalties may be levied in case of non-compliance
                    with any of these rules.
                </li>
                <li>
                    Falconcity will not be held responsible for any mistake
                    filled in this form.
                </li>
            </ol>
            <p>
                Failure to submit any of the above documents will result in the
                cancellation of your request.
            </p>
            <br />
            <b-form-checkbox
                id="checkbox-1"
                @change="agree()"
                name="checkbox-1"
                value="true"
                unchecked-value="not_accepted"
            >
                I understand and agree to the Terms & Conditions mentioned above
                and all the above filled information is true
            </b-form-checkbox>
            <div class="mt-3 mb-3">
                <button
                    type="submit"
                    :disabled="!agreed"
                    class="btn btn-primary"
                >
                    Submit
                </button>
            </div>
        </form>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import alertMixin from "../../../mixins/alertMixin";
// eslint-disable-next-line no-unused-vars
import { BASE_URL, LANDSCAPING_TYPES } from "../../common";
import axios from "axios";
import { required, numeric, requiredIf } from "vuelidate/lib/validators";

export default {
    mixins: [alertMixin],
    components: {
        Layout,
    },
    validations: {
        land: {
            company_driving_license: { required },
            company_request_letter: { required },
            company_trade_license: { required },
            company_vehicle_registration_detail: { required },
            contact_person_fax_no: { numeric },
            contact_person_mobile_no: { required, numeric },
            contact_person_name: { required },
            contact_person_office_no: { required, numeric },
            contractor_name: { required },
            scope_of_work: { required },
            date: { required },
            details: { required },
            drawings: { required },
            landscapingType: { required },
            letter_of_agreement: { required },
            title_deed: {
                required: requiredIf(function () {
                    return this.role === "Owner";
                }),
            },
            unit_id: { required },
            tenancy_contract: {
                required: requiredIf(function () {
                    return this.role === "Tenant";
                }),
            },
        },
    },
    data() {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
        const minDate = new Date(today);
        return {
            role: "",
            submitted: false,
            isOther: false,
            agreed: false,
            min: minDate,
            payment: "",
            selected: "",
            land: {
                company_driving_license: "",
                company_driving_license_filename: "",
                company_request_letter: "",
                company_request_letter_filename: "",
                company_trade_license: "",
                company_trade_license_filename: "",
                company_vehicle_registration_detail: "",
                company_vehicle_registration_detail_filename: "",
                contact_person_fax_no: "",
                contact_person_mobile_no: "",
                contact_person_name: "",
                contact_person_office_no: "",
                contractor_name: "",
                date: "",
                details: "",
                drawings: "",
                drawings_filename: "",
                landscapingType: [],
                letter_of_agreement: "",
                letter_of_agreement_filename: "",
                scope_of_work: "",
                scope_of_work_filename: "",
                sub_contractor_company_letter: "",
                sub_contractor_company_letter_filename: "",
                sub_contractor_company_trade_license: "",
                sub_contractor_company_trade_license_filename: "",
                title_deed: "",
                title_deed_filename: "",
                type_others: "",
                unit_id: "",
                tenancy_contract: "",
                tenancy_contract_filename: "",
            },
        };
    },
    mounted() {
        this.role = localStorage.cs_user_role;
        console.log("the role is ", this.role);
        this.$store.dispatch("apidata/fetchCountries");
    },
    methods: {
        agree() {
            this.agreed = !this.agreed;
        },
        requestLandscapingRequest() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                console.log(this.land);
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}landscaping?token=${localStorage.cs_user_token}`,
                            this.land
                        )
                        .then((res) => {
                            // this.resetFields();
                            this.agreed = false;
                            console.log(res.data.response);
                            this.successMsg(
                                res.data.response.ref_no,
                                "Success! Your request has been received by the Customer Service Team."
                            );
                            loader.hide();
                            this.$router.push("/");
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },

        // eslint-disable-next-line no-unused-vars
        showOther() {
            let result = this.land.landscapingType.find(
                (el) => el === "Others"
            );
            if (result == undefined) {
                this.isOther = false;
            } else {
                this.isOther = true;
            }
        },
        handleReleaseLetter(e) {
            let id = e.srcElement.id;
            this.constructBase64(e, id);
        },

        constructBase64(e, id) {
            let reader = new FileReader();

            reader.onload = (f) => {
                switch (id) {
                    case "titleDeed":
                        this.land.title_deed_filename = e.target.files[0].name;
                        this.land.title_deed = f.target.result.split(",")[1];
                        break;
                    case "tenancyContract":
                        this.land.tenancy_contract_filename =
                            e.target.files[0].name;
                        this.land.tenancy_contract =
                            f.target.result.split(",")[1];
                        break;
                    case "agreement":
                        this.land.letter_of_agreement_filename =
                            e.target.files[0].name;
                        this.land.letter_of_agreement =
                            f.target.result.split(",")[1];
                        break;
                    case "scope":
                        this.land.scope_of_work_filename =
                            e.target.files[0].name;
                        this.land.scope_of_work = f.target.result.split(",")[1];
                        break;
                    case "drawing":
                        (this.land.drawings_filename = e.target.files[0].name),
                            (this.land.drawings =
                                f.target.result.split(",")[1]);
                        break;
                    case "companyRequest":
                        (this.land.company_request_letter_filename =
                            e.target.files[0].name),
                            (this.land.company_request_letter =
                                f.target.result.split(",")[1]);
                        break;
                    case "companyTrade":
                        (this.land.company_trade_license_filename =
                            e.target.files[0].name),
                            (this.land.company_trade_license =
                                f.target.result.split(",")[1]);
                        break;
                    case "companyVehicle":
                        (this.land.company_vehicle_registration_detail_filename =
                            e.target.files[0].name),
                            (this.land.company_vehicle_registration_detail =
                                f.target.result.split(",")[1]);
                        break;
                    case "companyDriving":
                        (this.land.company_driving_license_filename =
                            e.target.files[0].name),
                            (this.land.company_driving_license =
                                f.target.result.split(",")[1]);
                        break;
                    case "companyContractorLetter":
                        (this.land.sub_contractor_company_letter_filename =
                            e.target.files[0].name),
                            (this.land.sub_contractor_company_letter =
                                f.target.result.split(",")[1]);
                        break;
                    case "contractorTrade":
                        (this.land.sub_contractor_company_trade_license_filename =
                            e.target.files[0].name),
                            (this.land.sub_contractor_company_trade_license =
                                f.target.result.split(",")[1]);
                        break;
                }
            };
            reader.readAsDataURL(e.target.files[0]);
        },
    },
    computed: {
        landscapingOptions() {
            return LANDSCAPING_TYPES.map((ele) => {
                return {
                    item: ele,
                    name: ele,
                };
            });
        },
        userUnits() {
            return this.$store.getters["apidata/getUserInfo"];
            // return JSON.parse(localStorage.user_info).units;
        },
        mappedUserUnits() {
            return this.userUnits?.units?.map((ele) => {
                return {
                    value: ele.unit_id,
                    text: ele.unit_no,
                    payment: ele.payment_type,
                };
            });
        },
    },
};
</script>
<style scoped>
.buyers {
    border: 1px solid red;
}
</style>
